import {Component, OnInit,} from '@angular/core';
import {endOfHour, isSameDay, isSameMonth,} from 'date-fns';
import {CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView,} from 'angular-calendar';
import {AppService, InfoData, RemindEvent, SmsData,} from '../../services/app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, ReminderDetails} from "../../services/auth.service";
import {CalendarEvent} from "calendar-utils";
import {ObservableService} from "../../services/observable.service";
import {AppColors} from "../../services/app.colors";
import {Subject} from "rxjs";
import {Location} from "@angular/common";
import {SmsEventDialogComponent} from "../sms-event-dialog/sms-event-dialog.component";

const EXT_PREFIX = "ext_";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  locale: string = 'pl';
  refresh: Subject<any> = new Subject();
  events: CalendarEvent<RemindEvent>[] = [];
  activeDayIsOpen: boolean = true;
  selectedDate = new Date();
  dayStartHour = 8;
  dayEndHour = 22;
  calendars: Map<string, RemindEvent[]> = new Map()
  public selected = {}
  isCollapsed = true;
  filterByName: string;
  private eventsTmp: CalendarEvent<RemindEvent>[];

  constructor(private appService: AppService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private observableService: ObservableService,
              private router: Router,
              private location: Location) {
  }

  ngOnInit(): void {
    if (!this.authService.isAuthenticated()) {
      this.observableService.loginEvent.next(() => {
        this.authService.loadUserData().reminders.forEach(value => this.selected[value.calendarId] = true)
        this.loadData(this.viewDate);
      })
    } else {
      this.authService.loadUserData().reminders.forEach(value => this.selected[value.calendarId] = true)
      this.loadData(this.viewDate);
    }

    this.observableService.clearDataObs.subscribe(data => {
      this.clearData();
    });
    this.observableService.calendarDataObs.subscribe(data => {
      this.setData(data);
    });
    this.observableService.foreignEventsObs.subscribe(data => {
      this.setForeignEvents(data);
    });
  }

  private loadData(viewDate: Date) {
    this.appService.getAllData(viewDate);
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent<SmsData>[] }): void {
    console.log('day clicked' + date);
    this.selectedDate = date;
    this.setDefaultNotifyHur(this.initNewDate());

    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0);
      this.viewDate = date;
    }
  }

  hourClicked(date: Date) {
    this.selectedDate = date;
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    let notifyOption = SmsEventDialogComponent.notifyOf((event.meta as RemindEvent).smsData.notifyOption);
    let notifyDate = SmsEventDialogComponent.computeNotify(notifyOption, newStart);
    let modifiedSmsData: SmsData = {...event.meta.smsData,  notifyAt: notifyDate}
    let meta = {...event.meta, start: newStart, end: newEnd, smsData: modifiedSmsData};
    let modifiedEvent = {...event, meta: meta}
    console.log("modified ");
    console.log(modifiedEvent);
    this.handleClickOnEvent('Dropped or resized', modifiedEvent);
  }

  handleClickOnEvent(action: string, event: CalendarEvent): void {
    if (this.isNotForeignEvent(event)) {
      this.hourClicked(event.start);
      this.showEditDialog(event);
    }
  }

  private isNotForeignEvent(event: CalendarEvent<any>) {
    return !event.meta.foreign;
  }

  private isSmsEvent(event: CalendarEvent<RemindEvent>) {
    return event.meta.smsData;
  }

  private showEditDialog(event: CalendarEvent<RemindEvent>) {
    if (this.isSmsEvent(event)) {
      this.observableService.remindSmsEditEvent.next(event.meta)
    } else {
      this.observableService.remindEditEvent.next(event.meta)
    }
  }

  addEventWithDialog() {
    let start = CalendarComponent.setHourForDate(this.selectedDate, this.dayStartHour - 1);
    let remindEvent: RemindEvent = {
      infoData: new InfoData(),
      start: start,
      end: endOfHour(start)
    }
    this.observableService.remindEditEvent.next(remindEvent)
  }

  addSmsEvent() {
    let eventDate = this.initNewDate();
    let smsData = new SmsData(this.setDefaultNotifyHur(eventDate), "");
    smsData.notifyOption = "day_before_at_16"
    let smsEvent: RemindEvent = {smsData: smsData, start: eventDate}
    this.observableService.remindSmsEditEvent.next(smsEvent)
  }

  public mapToCalendarSmsEvent(startDate: Date, remindEvent: RemindEvent, id: string): CalendarEvent<RemindEvent> {
    return {
      id: id,
      title: 'title',
      start: startDate,
      end: endOfHour(startDate),
      color: AppColors.colors.blue,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      }, meta: remindEvent
    };
  }


  private initNewDate(): Date {
    let newDate = new Date(this.selectedDate);
    newDate.setHours(this.dayStartHour, 0, 0, 0);
    return newDate;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(event: Date) {
    this.filterByName = "";
    this.observableService.calendarDataObs.subscribe(data => {
      this.setData(data);
    });
    this.loadData(event)
    this.activeDayIsOpen = false;
  }

  private setForeignEvents(events: RemindEvent[]) {
    if (events.length < 1) return
    this.events = this.events.filter(value => !value.meta.calendarId.startsWith(EXT_PREFIX));
    events.forEach(value => {
      console.log("Type is: " + typeof value)
      if (value.smsData != null) {
        value.smsData.foreign = true
        let foreignEvent = this.decorateForeignEvent(this.mapToCalendarSmsEvent(new Date(value.start), value, value.id as string));
        this.events = this.events = [
          ...this.events, foreignEvent
        ];
      } else {
        let calendarEvent = this.decorateInfoEvent(value, AppColors.colors.foreign);
        this.events = this.events = [
          ...this.events, calendarEvent
        ];
      }

    });
    this.sortEvents();
    this.refresh.next();
  }

  private decorateForeignEvent(event: CalendarEvent): CalendarEvent {
    event.color = AppColors.colors.foreign;
    event.meta.calendarId = EXT_PREFIX + event.meta.calendarId
    return this.updateTitle(event);
  }


  private setData(events: RemindEvent[]) {
    if (events?.length < 1) return
    let calendarId = events[0]?.calendarId;
    this.calendars.set(calendarId, events);
    this.events = this.events.filter(value => value.meta.calendarId != calendarId)

    if (events != null) {
      events.forEach(value => {
        if (value.smsData != null) {
          value.smsData.notifyAt = new Date(value.smsData.notifyAt)
          let calendarEvent = this.updateTitle(this.decorateEvent(this.mapToCalendarSmsEvent(new Date(value.start), value, value.id as string)));
          this.events = this.events = [
            ...this.events, calendarEvent
          ];
        } else {
          let calendarEvent = this.decorateInfoEvent(value);
          this.events = this.events = [
            ...this.events, calendarEvent
          ];
        }
      });

    }
    this.sortEvents();
    this.refresh.next();
  }

  private sortEvents() {
    this.events = this.events.sort((n1, n2) => n1.start.getTime() - n2.start.getTime())
  }

  private decorateEvent(event: CalendarEvent<RemindEvent>): CalendarEvent {
    //FIXME calendar Event `start` property occurs after the `end`
    let metData = event.meta.smsData
    let colorSetName = this.authService.loadUserData().reminders.find(value => value.calendarId === event.meta.calendarId).colorSet
    let colorSet = AppColors.getColorSetByName(colorSetName)
    if (event.start.getHours() >= 14) {
      event.color = metData.sent ? colorSet.lateSent : colorSet.late
    } else {
      event.color = metData.sent ? colorSet.earlySent : colorSet.early
    }
    return event;
  }

  private updateTitle(event: CalendarEvent<RemindEvent>): CalendarEvent<RemindEvent> {
    let metData = event.meta.smsData;
    return Object.assign({}, event, {
      title: event.start.getHours() + ':' + event.start.getMinutes() + ' '
        + this.createHolderPrefix(event)
        + (metData.name ? metData.name : metData.phoneNumber) +
        ': '
        + (metData.info ? metData.info : metData.message),
      actions: event.meta.repeat ? this.repeatActions : null,
    });
  }

  //FIXME deprecated
  private createHolderPrefix(event: CalendarEvent<RemindEvent>) {
    return event.meta.smsData.holder == null ? "" : event.meta.smsData.holder + ' - ';
  }


  private decorateInfoEvent(infoEvent: RemindEvent, colorByName = AppColors.getColorByName(infoEvent.infoData.color)): CalendarEvent {
    let start = new Date(infoEvent.start);
    return {
      id: infoEvent.id,
      title: start.getHours() + ':' + start.getMinutes() + ' ' + infoEvent.infoData?.info,
      start: new Date(start),
      end: new Date(infoEvent.end),
      color: colorByName,
      draggable: true,
      actions: this.infoActions,
      allDay: CalendarComponent.notTheSameDay(infoEvent),

      resizable: {
        beforeStart: true,
        afterEnd: true,
      }, meta: infoEvent
    }
  }

  infoActions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-info"></i>',
      a11yLabel: 'Info',
      onClick: ({event}: { event: CalendarEvent }): void => {
      },
    },
  ];

  repeatActions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-calendar"></i>',
      a11yLabel: 'Info',
      onClick: ({event}: { event: CalendarEvent }): void => {
      },
    },
  ];

  private static notTheSameDay(infoEvent: RemindEvent) {
    return new Date(infoEvent.start).getDate() != new Date(infoEvent.end).getDate();
  }

  onChangeSearch() {
    this.events = []
    this.calendars.forEach((calendar, key) => {
      let next = calendar;
      let events = this.filterByName.length < 2 ? next : next.filter(value => value.smsData?.name.toLowerCase()
        .match(".*" + this.filterByName.toLowerCase()));
      events.forEach(value => {
        if (value.smsData != null) {
          let calendarEvent = this.updateTitle(this.decorateEvent(this.mapToCalendarSmsEvent(new Date(value.start), value, value.id as string)));
          this.events = this.events = [
            ...this.events, calendarEvent
          ];
        }
      });
    })
    this.refresh.next()

  }

  private static setHourForDate(fromDate, hour: number) {
    let date = new Date(fromDate);
    date.setHours(hour);
    date.setSeconds(0);
    date.setMinutes(0);
    return date;
  }

  private setDefaultNotifyHur = (fromDate): Date => CalendarComponent.setHourForDate(fromDate, this.dayStartHour);


  public getUserCalendars(): ReminderDetails[] {
    return this.authService.loadUserData().reminders
  }

  public getColorSet(colorSetName: string) {
    return AppColors.colorSet.get(colorSetName)
  }

  selectReminder(reminder: ReminderDetails) {
    this.selected[reminder.calendarId] = !this.selected[reminder.calendarId]
    if (this.selected[reminder.calendarId]) {
      this.setData(this.calendars.get(reminder.calendarId))
    } else {
      this.events = this.events.filter(value => value.meta.calendarId !== reminder.calendarId);
    }
  }

  private clearData() {
    this.events = []
  }
}
