import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppService, SmsData, RemindEvent, InfoData} from "../../services/app.service";
import {Polish} from "flatpickr/dist/l10n/pl";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {endOfHour} from "date-fns";
import {ObservableService} from "../../services/observable.service";
import {AppColors} from "../../services/app.colors";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.css']
})
export class EventDialogComponent implements OnInit {

  constructor(private modal: NgbModal,
              private appService: AppService,
              private observable: ObservableService,
              private authService: AuthService,
              private router: Router) {
  }

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;

  modalData: {
    action: string;
    event?: RemindEvent;
  };
  beforeSubmit = true;
  locale = Polish;
  invalidTimeMessage: "Złe daty";
  smsRemind = true;
  colors = [
    {name: 'yellow', color: AppColors.colors.yellow},
    {name: 'green', color: AppColors.colors.green},
    {name: 'pink', color: AppColors.colors.pink},
    {name: 'orange', color: AppColors.colors.orange},
  ];

  ngOnInit(): void {
    this.modalData = {event: this.initEvent(new Date()), action: ''};
    this.observable.remindEditObs.subscribe(value => {
      this.beforeSubmit = true;
      this.showEdit(value);
    })
  }

  private showEdit<T>(value: RemindEvent) {
    this.setModelData(value);
    this.show();
  }

  show() {
    this.modal.open(this.modalContent, {size: 'lg'});
  }

  private setModelData(value: RemindEvent) {
    this.modalData.event.id = value.id;
    this.modalData.event.start = value.start;
    this.modalData.event.end = value.end;
    this.modalData.event.smsData = value.smsData;
    this.modalData.event.infoData = value.infoData;
    this.modalData.event.infoData.color = AppColors.colors.grey.name;
  }


  public initEvent(startDate: Date): RemindEvent {
    return {
      start: startDate,
      end: endOfHour(startDate),
      infoData: new InfoData(),
      smsData: new SmsData()
    };
  }

  setEndDate($event) {

  }

  datesValid() {
    return false;
  }

  deleteEvent(event: RemindEvent | undefined) {
    this.appService.deleteEvent(event)
    return true
  }

  saveEvent(eventToAdd: RemindEvent) {
    console.log("saving")
    this.beforeSubmit = false;
    if (this.messageValid()) {
      if (!this.authService.isAuthenticated()) {
        this.observable.loginEvent.next(() => {
          this.appService.saveOrUpdate(eventToAdd).subscribe(value => {});
          this.router.navigate(['/calendar']);
        })
      } else {
        this.appService.saveOrUpdate(eventToAdd).subscribe(value => {});
        this.router.navigate(['/calendar']);
        return true;
      }
      this.appService.saveOrUpdate(eventToAdd).subscribe(value => {});
      return true;
    }
    return false;
  }

  messageValid() {
    return this.beforeSubmit || this.modalData?.event?.infoData?.info;
  }
}
