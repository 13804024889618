import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthData, AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent implements OnInit {

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  pkey: string;
  loggedIn: boolean;

  constructor(private modal: NgbModal, private route: ActivatedRoute,
              private router: Router, private authService: AuthService) {
    this.route.paramMap.subscribe(params => {
      this.pkey = params.get('reminderName');
    });
    this.loggedIn = authService.getAuthToken() !== null;

  }

  ngOnInit(): void {
    this.logout();
  }

  show() {
    this.modal.open(this.modalContent, {size: 'lg'});
  }

  logout() {
    this.authService.logout();
    this.loggedIn =false;
    this.router.navigate(['/home']);
  }
}
