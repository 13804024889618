
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center" >

      <div class="logo me-auto" >
        <h1><a href="index.html">Przypomnij Sie</a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>
      <div >
  <nav id="navbar" class="navbar order-last order-lg-0 " >
    <ul>
      <li><a class="nav-link scrollto active" routerLink="/home">Home</a></li>
      <li><a class="nav-link scrollto" routerLink="/calendar">Kalendarz</a></li>
      <li><a class="nav-link scrollto active" routerLink="/contact">Kontakt</a></li>
      <li><a class="nav-link scrollto active" (click)="addSmsEventWithDialog()">Powiadom</a></li>
      <li class="dropdown" ><a ><span>{{getUser()}}</span> <i class="bi bi-chevron-down"></i></a>
        <ul>
          <li [hidden]="!isLogged()"><a class="nav-link scrollto" routerLink="/user">Profil</a></li>
          <li [hidden]="!isLogged()"><a class="nav-link scrollto" routerLink="/clients">Klienci</a></li>
          <li [hidden]="!isLogged()"><a class="nav-link scrollto" routerLink="/logout">Wyloguj</a></li>
          <li [hidden]="isLogged()"><a class="nav-link scrollto" (click)="login()">Zaloguj</a></li>
        </ul>
      </li>
    </ul>
  </nav><!-- .navbar -->
      </div>
      <div class="mobile-nav-toggle" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">

        <nav id="navbarmobile" class="navbar order-last order-lg-0 navbar-mobile" >
          <!--    navbar-mobile-->
          <ul>
            <li><a class="nav-link scrollto active">{{getUser()}}</a></li>
            <li><a class="nav-link scrollto active" routerLink="/home" (click)="collapse.toggle()">Home</a></li>
            <li><a class="nav-link scrollto active" routerLink="/calendar" (click)="collapse.toggle()">Kalendarz</a></li>
            <li [hidden]="!isLogged()"><a class="nav-link scrollto active" routerLink="/clients">Klienci</a></li>
            <li><a class="nav-link scrollto active" (click)="addSmsEventWithDialog();collapse.toggle();">Powiadom</a></li>
            <!--      <li><a class="nav-link scrollto active" routerLink="/contact">Ad Note</a></li>-->
            <li [hidden]="!isLogged()"><a class="nav-link scrollto active" routerLink="/logout" (click)="collapse.toggle()">Wyloguj</a></li>
            <li [hidden]="isLogged()"><a class="nav-link scrollto active" (click)="login();collapse.toggle();">Zaloguj</a></li>
            <li [hidden]="!isLogged()"><a class="nav-link scrollto active" routerLink="/user" (click)="collapse.toggle();">Profil</a></li>
            <li><a class="nav-link scrollto active" routerLink="/contact" (click)="collapse.toggle()">Kontakt</a></li>
          </ul>
          <i class="bi mobile-nav-toggle bi-x" (click)="collapse.toggle()"></i>

        </nav>
      </div>
      <i class="bi bi-list mobile-nav-toggle" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"></i>
</div>
  </header>

