import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {InfoData, RemindEvent, SmsData} from "../../services/app.service";
import {endOfHour} from "date-fns";
import {ObservableService} from "../../services/observable.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public key: string;

  constructor(private route: ActivatedRoute, private router: Router
    , private authService: AuthService,
              private observableService: ObservableService) {
    this.key = authService.getName()
  }

  addSmsEventWithDialog() {
    console.log("adding event")
    let remindEvent: RemindEvent = {smsData: new SmsData(new Date(), ""), start:  new Date()}
    this.observableService.remindSmsEditEvent.next(remindEvent)
  }

  ngOnInit(): void {

  }

  login() {
    this.observableService.loginEvent.next(()=>{this.router.navigate([ '/calendar']);})
  }

  getUser() {
    return this.authService.loadUserData() ? this.authService.loadUserData().phoneNumber : ""
  }
}
