<section>
  <div class="container-fluid">
    <div class="">
    <div class="row">
      <div class="col-2">
        <div class="small">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="filterByName"
            (ngModelChange)="onChangeSearch()"
          />
        </div>
<!--        <div class="small" style="overflow: auto" *ngFor="let calendar of getUserCalendars()" [hidden]="getUserCalendars().length < 2 "-->
<!--             (click)="selectReminder(calendar)"-->
<!--             [ngStyle]="{'color': (selected[calendar.calendarId]  ? '#0d6efd' : 'grey')}">-->
<!--          {{calendar.name}}-->
<!--        </div>-->
      </div>
      <div class="col">
         {{ viewDate | calendarDate:(view + 'ViewTitle'):'pl' }}
      </div>
      <div class="col-auto">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          M
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          T
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          D
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          ><
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >.
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay($event)"
          >>
          </div>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" (click)="addSmsEvent()">
          Sms
        </button>
        <button class="btn btn-primary" (click)="addEventWithDialog()">
          Info
        </button>
      </div>
    </div>
    </div>

  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleClickOnEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      [weekStartsOn]=1
      [locale]="locale"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (hourSegmentClicked)="hourClicked($event.date)"
      (eventClicked)="handleClickOnEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      [dayStartHour]=dayStartHour
      [dayEndHour]=dayEndHour
      [weekStartsOn]=1
      [locale]="locale"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleClickOnEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (hourSegmentClicked)="hourClicked($event.date)"
      [dayStartHour]=dayStartHour
      [dayEndHour]=dayEndHour
      [locale]="locale"

    >
    </mwl-calendar-day-view>
  </div>
  </div>
</section>

