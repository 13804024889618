import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ObservableService} from "../../services/observable.service";
import {RemindEvent, SmsData} from "../../services/app.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isCollapsed: boolean = true;
  constructor(private route: ActivatedRoute, private router: Router
    , private authService: AuthService,
              private observableService: ObservableService) {
  }
  ngOnInit(): void {

  }

  addSmsEventWithDialog() {
    console.log("adding event")
    let remindEvent: RemindEvent = {smsData: new SmsData(new Date(), ""), start:  new Date()}
    this.observableService.remindSmsEditEvent.next(remindEvent)
  }

  login() {
    this.observableService.loginEvent.next(()=>{this.router.navigate(['/calendar']);})
  }

  isLogged(): boolean {
    return this.authService.getAuthToken() !== null
  }

  getUser() {
    return this.authService.loadUserData() ? this.authService.loadUserData().name : ""
  }



}
