<!--<link href="https://unpkg.com/bootstrap-css-only@4.4.1/css/bootstrap.min.css" rel="stylesheet">-->
<link href="https://unpkg.com/@fortawesome/fontawesome-free@5.15.1/css/all.css" rel="stylesheet">
<link href="https://unpkg.com/angular-calendar@0.28.22/css/angular-calendar.css" rel="stylesheet">
<link href="https://unpkg.com/flatpickr@4.6.6/dist/flatpickr.css" rel="stylesheet">

<app-navbar></app-navbar>
<div class="top-margin-12">
<main id="main" >

<router-outlet></router-outlet>

</main>
</div>
<app-footer></app-footer>
<!--<div id="preloader"></div>-->
<app-event-dialog></app-event-dialog>
<app-sms-event-dialog></app-sms-event-dialog>
<app-login-dialog></app-login-dialog>
