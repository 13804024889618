import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ObservableService} from "../../services/observable.service";
import {AppService, UserDetails} from "../../services/app.service";

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent {

  @ViewChild('modalRegister', {static: true}) modalRegister: TemplateRef<any>;

  @ViewChild('modalConfirmCode', {static: true}) modalConfirmCode: TemplateRef<any>;

  @ViewChild('modalVerifyNumber', {static: true}) modalVerifyNumber: TemplateRef<any>;

  loggedIn: boolean;

  emptyErrorMessage = 'Błędny kod';
  code: number;
  private isValid = true;
  beforeSubmit = true;
  callback: Function

  userDetails: UserDetails = new UserDetails()

  constructor(private modal: NgbModal, private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private appService: AppService,
              private observableService: ObservableService) {
    this.observableService.loginObs.subscribe(value => {
      this.callback = value;
      console.log("loginHandled and set" + value)
      this.showLogin();
    })

  }

  valid(): boolean {
    return this.isValid;
  }

  verifyByCode() :boolean{
    this.beforeSubmit = false;
    if (isNaN(this.code) || !this.validateCode()) {
      this.isValid = false;
      return false;
      // this.showVerify();
    } else {
      this.authService.approveToken(
        { code: this.code, phoneNumber: this.userDetails.phoneNumber})
        .subscribe(value => {
        if (value.authenticated) {
          this.authService.setAuthResult(value);
          this.callback();
        } else {
          this.isValid = false;
          this.showVerify();
        }
      })
      this.beforeSubmit = true;
      return true;
    }
  }

  logout() {
    this.authService.logout();
    this.loggedIn = false;
    this.router.navigate(['/home']);
  }

  initVerify():boolean {
    this.beforeSubmit = false;
    if(this.validateUser()) {
      this.initVerification(this.userDetails);
      this.beforeSubmit = true;
      return true;
    }
    return false;
  }

  public validateUser():boolean {
    return this.beforeSubmit || this.userDetails.phoneNumber!=null;
  }

  private initVerification(userDetails :UserDetails) {
    this.authService.auth(userDetails).subscribe(value => {
        this.showVerify();
    })
  }

  private showVerify() {
    this.modal.open(this.modalConfirmCode, {size: 'lg'});
  }

  private showLogin() {
    this.clean();
    this.modal.open(this.modalVerifyNumber, {size: 'lg'});
  }

  resend() {
    this.cleanCodeAndValidation();
    this.initVerify()
  }

  private clean() {
    this.cleanCodeAndValidation();
    this.userDetails = new UserDetails();
  }

  private cleanCodeAndValidation() {
    this.isValid = true;
    this.code = null;
  }

  private executeCallback() {
    this.callback();
    this.callback = () =>console.log("callback CLEARED should never invoke");
  }

  register() {
    this.modal.open(this.modalRegister, {size: 'lg'});
  }

  validateCode():boolean {
    return this.beforeSubmit || this.code!=null;
  }
}
