import {Component, OnInit} from '@angular/core';
import {AppService, Client, RemindEvent} from "../../services/app.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  private clients: Map<string, Client[]> = new Map();
  public clientsFlat: Client[] = []

  constructor(private appService: AppService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.loadData();

  }

  private loadData() {
    this.authService.loadUserData().reminders.forEach(reminder => {
      this.getClientsData(reminder.calendarId)
    })
  }

  private getClientsData(calendarId: string) {
    if (calendarId == this.authService.loadUserData().primaryReminder) {
      this.appService.getClients(calendarId).subscribe(value => {
        value.forEach(value1 => {
          value1.calendarId = calendarId;
          this.clientsFlat = [...this.clientsFlat, value1]
        })
      })
    }
  }

  deleteClient(client: Client) {
    this.appService.deleteClient(client).subscribe(value => {
      window.location.reload();
    })

  }
}
