<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Dodaj powiadomienie</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div>
        Nazwa:
        <div>
          <ng-autocomplete
            [data]="getNamesData()"
            [searchKeyword]="keyword"
            [ngModel]="modalData?.event?.smsData?.name"
            (selected)='selectEvent($event)'
            (inputChanged)='onChangeSearch($event)'
            (inputFocused)='onFocused($event)'
            (inputCleared)="clearSelected()"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
      <div>
        Wiadomość:
        <textarea [maxLength]="countLimit()" class="form-control" [(ngModel)]="getSmsData().message"></textarea>
        {{getSmsData().message.length}}/{{countLimit()}}
        <div *ngIf="!messageValid()">
          <p style="color:red !important;"> błędna wartość</p>
        </div>
      </div>
      <div>
        <input
          type="checkbox" (ngModelChange)="resetMessageInput()"
          [(ngModel)]="getSmsData().addEventTime"
        />
        Dodaj czas wydarzenia:
        <input
          type="checkbox" (ngModelChange)="resetMessageInput()"
          [(ngModel)]="getSmsData().replaceNonAscii"
        />
        Usuń polskie znaki:
        <input
          type="checkbox" (ngModelChange)="resetMessageInput()"
          [(ngModel)]="getSmsData().allowDouble"
        />
        sms podwójny
        <input
          type="checkbox" [(ngModel)]="repeat" (click)="initRepeat()"
        />
        powtarzaj
      </div>
      <div *ngIf="modalData?.event.repeat != null">
        co
        <input size="2"
               type="input"
               [(ngModel)]="modalData.event.repeat.everyWeek" [disabled]="repeatNotChanged()"
        /> tydzień,
        <input size="2"
               type="input"
               [(ngModel)]="modalData.event.repeat.count" [disabled]="repeatNotChanged()"
        /> razy
      </div>
      <div>
        Opis:
        <input
          type="text"
          class="form-control"
          [(ngModel)]="getSmsData().info"
        />
      </div>
    </div>
    <div>
      Telefony:
      <input
        type="text"
        class="form-control"
        [(ngModel)]="getSmsData().phoneNumber"
      />
    </div>
    <div *ngIf="!phoneNumberValid()">
      <p style="color:red !important;"> {{emptyErrorMessage}}</p>
    </div>

    <div>
      Czas wydarzenia:
      <input
        class="form-control"
        type="text"
        mwlFlatpickr
        [(ngModel)]="modalData?.event.start"
        [altInput]="true"
        [convertModelValue]="true"
        [enableTime]="true"
        dateFormat="Y-m-dTH:i"
        altFormat="F j, Y H:i"
        placeholder="Not set"
        [time24hr]=true
        [locale]="locale"
        (flatpickrChange)="setCurrentNotify($event)"
      />
      <div *ngIf="!datesValid()">
        <p style="color:red !important;"> {{invalidTimeMessage}}</p>
      </div>
    </div>
    <div>
      Czas przypomnienia:
      <div>
        <select [(ngModel)]='selectedNotify' class='form-control' (change)="setCurrentNotify($event)">
          <option [ngValue]="option" *ngFor="let option of getNotifyOptions()">{{option.description}}</option>
        </select>
      </div>
      <div>
        <input
          class="form-control"
          type="text"
          mwlFlatpickr
          [(ngModel)]="getSmsData().notifyAt"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-dTH:i"
          altFormat="F j, Y H:i"
          placeholder="Not set"
          [time24hr]=true
          [locale]="locale"
        />
        <div *ngIf="!datesValid()">
          <p style="color:red !important;"> {{invalidTimeMessage}}</p>
        </div>
      </div>
    </div>
    <div [hidden]="reminders == null || reminders.length == 0">
      <select [(ngModel)]='modalData.event.calendarId' class='form-control' style="width: 200px">
        <option [ngValue]="option" *ngFor="let option of reminders"
                [ngStyle]="{'background-color': getColorSet(option.colorSet).early.primary}"
                [value]="option.calendarId">{{option.name}}</option>
      </select>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="deleteEvent(modalData?.event); close();"
            [hidden]="!editable()">
      Usuń
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="changeWithFuture(modalData?.event); close();"
            [hidden]="this.modalData?.event.seriesId==null">
      Usuń następne
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="saveEvent(modalData?.event)? close():'';">
      Zapisz
    </button>
  </div>
</ng-template>
