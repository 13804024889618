<ng-template #modalVerifyNumber let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Logowanie</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!loggedIn">
      Podaj numer telefonu:
      <input
        type="text"
        class="form-control"
        [(ngModel)]="userDetails.phoneNumber"
      />
      <div *ngIf="!validateUser()">
        <p style="color:red !important;">Wartość wymagana</p>
      </div>
      <a (click)="register(); close();">Zarejestruj </a>
    </div>
  </div>
  <div class="modal-footer" >
    <button *ngIf="loggedIn" type="button" class="btn btn-outline-secondary" (click)="logout();close();">
      Wyloguj
    </button>
    <button *ngIf="!loggedIn" type="button" class="btn btn-outline-secondary" (click)="initVerify()? close():''">
      Potwierdź
    </button>

  </div>
</ng-template>

<ng-template #modalRegister let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Rejstracja</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!loggedIn">
      Imię:
      <input
        type="text"
        class="form-control"
        [(ngModel)]="userDetails.name"
      />
      *Numer telefonu:
      <input
        type="text"
        class="form-control"
        [(ngModel)]="userDetails.phoneNumber"
      />
      <div *ngIf="!validateUser()">
        <p style="color:red !important;">Wartość wymagana</p>
      </div>
      Email:
      <input
        type="text"
        class="form-control"
        [(ngModel)]="userDetails.email"
      />
    </div>
  </div>
  <div class="modal-footer" >
    <button *ngIf="!loggedIn" type="button" class="btn btn-outline-secondary" (click)="initVerify()? close():''">
      Rejestruj
    </button>
  </div>
</ng-template>


<ng-template #modalConfirmCode let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Potwierdzenie</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Wpisz kod otrzymany smsem:
      <input
        type="text"
        class="form-control"
        [(ngModel)]="code"
      />
      <div *ngIf="!valid()">
        <p style="color:#ff0000 !important;"> {{emptyErrorMessage}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
<!--    <button type="button" class="btn btn-outline-secondary" (click)="close()">-->
<!--      Close-->
<!--    </button>-->
    <button *ngIf="!loggedIn" type="button" class="btn btn-outline-secondary" (click)="resend(); close();">
      Wyślij ponownie ponownie
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="verifyByCode()? close():''">
      Potwierdź
    </button>
  </div>
</ng-template>


<section>
  Zostałeś wylogowany, zaloguj się ponownie
</section>
