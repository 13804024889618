<section class="contact section-bg">
  <div class="container" >
    <ul class="list-group-numbered list-group-light" >
      <li *ngFor="let client of clientsFlat" class="list-group-item" >
        <a href="javascript: void(0);" (click)="deleteClient(client)" >usuń</a>
        {{client.name}}, {{client.phoneNumber}}
      </li>

    </ul>
  </div>
</section>
