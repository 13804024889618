

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Dodaj informacje</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div>
        Info:
        <input
          type="text"
          class="form-control"
          [(ngModel)]="modalData?.event.infoData.info"
        />
      </div>
      <div *ngIf="!messageValid()">
        <p style="color:red !important;">Wartość wymagana</p>
      </div>
    </div>
    <div>
      Od:
      <input
        class="form-control"
        type="text"
        mwlFlatpickr
        [(ngModel)]="modalData?.event.start"
        [altInput]="true"
        [convertModelValue]="true"
        [enableTime]="true"
        dateFormat="Y-m-dTH:i"
        altFormat="F j, Y H:i"
        placeholder="Not set"
        [time24hr]=true
        [locale]="locale"
        (flatpickrChange)="setEndDate($event)"
      />
      <div *ngIf="!datesValid()">
        <p style="color:red !important;"> {{invalidTimeMessage}}</p>
      </div>
    </div>
    <div>
      Do:
      <div>
        <input
          class="form-control"
          type="text"
          mwlFlatpickr
          [(ngModel)]="modalData?.event.end"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-dTH:i"
          altFormat="F j, Y H:i"
          placeholder="Not set"
          [time24hr]=true
          [locale]="locale"
        />
        <div *ngIf="!datesValid()">
          <p style="color:red !important;"> {{invalidTimeMessage}}</p>
        </div>
<div>
  <div>
    <select [(ngModel)]='modalData.event.infoData.color' class='form-control' style="width: 200px">
      <option [ngValue]="option" *ngFor="let option of colors" [ngStyle]="{'background-color': option.color.primary}" [value]="option.color.name">{{option.name}}</option>
    </select>
  </div>
</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="deleteEvent(modalData?.event); close();"
            [hidden]="!modalData?.event.id">
      Usuń
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="saveEvent(modalData?.event)? close():'';"
            [hidden]="modalData?.event.id">
      Zapisz
    </button>
  </div>
</ng-template>
