import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {ObservableService} from "./observable.service";
import {AuthService, ReminderDetails} from "./auth.service";
import {Router} from "@angular/router";
import {catchError, finalize} from "rxjs/operators";


export class Client {
  id?: string
  name?: string
  phoneNumber?: string
  message?: string
  info?: string
  calendarId?:string
}

export class Repeat {
  count?: number
  everyWeek?: number
}

export class RemindEvent {
  id?: string | number;
  start: Date;
  end?: Date
  smsData?: SmsData = new SmsData()
  infoData?: InfoData = new InfoData();
  calendarId?: string;
  repeat?: Repeat;
  seriesId?: string;
}


export class InfoData {
  info: string;
  color: string
}


class ReminderConfig {
  ownerDescription: string
  publicAccessKey: string
  defaultMessage: string
}

export class CalendarData {
  calendarId: string;
  events: RemindEvent[] = []
  config: ReminderConfig
}

export class Holder {
  constructor(name: string, colorSet: string) {
    this.name = name;
    this.colorSet = colorSet;
  }

  name: string
  colorSet: string
}

export class SmsData {
  public phoneNumber: string;
  public message: string;
  public name: string;
  public notifyOption: string;
  public notifyAt: Date;
  public info: string;
  public sent: boolean;
  public foreign: boolean = false;
  public holder: string
  public addEventTime: boolean = true
  public replaceNonAscii: boolean = true
  public allowDouble: boolean = false
  public clientId: string

  constructor(notifyAt?: Date, message?: string) {
    this.notifyAt = notifyAt;
    this.message = message;
  }
}

export class UserDetails {
  id: string
  name: string
  phoneNumber: string
  reminders: ReminderDetails[]
  ownedReminders: ReminderDetails[]
  remindersRo: ReminderDetails[]
  smsAmount: Number
  email: string
  primaryReminder: string
  publicAccessKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient,
              private observable: ObservableService,
              private authService: AuthService,
              private router: Router) {
  }

  private getReminderName(): string {
    return this.authService.getPrimaryCalendar();
  }

  private viewDate: Date = new Date();

  public getEvents(reminderName: string, viewDate: Date = new Date(), from: Date = null, to: Date = null,) {
    this.viewDate = viewDate
    if (from == null) {
      from = new Date(viewDate)
      from.setMonth(viewDate.getMonth() - 1);
    }

    if (to == null) {
      to = new Date(viewDate)
      to.setMonth(viewDate.getMonth() + 1);
    }

    return this.http.get<RemindEvent[]>(environment.apiUrl + '/reminder/' + reminderName + '/events', {
      params: {
        from: from.toDateString(),
        to: to.toDateString()
      }
    }).subscribe(value => {
        this.observable.calendarData.next(value);
      },
      err => this.handleError(err),
      () => console.log("Processing Complete.")
    );
  }

  public getForeignData() {
    return this.http.get<RemindEvent[]>(environment.apiUrl + '/relatedEvents/').subscribe(value => {
      this.observable.foreignEvents.next(value);
    });
  }

  saveOrUpdate(eventToAdd: RemindEvent): Observable<any> {
    if (eventToAdd.calendarId == null) {
      eventToAdd.calendarId = this.authService.getPrimaryCalendar();
    }
    if (eventToAdd.id != null) {
      console.log(eventToAdd)
      return this.updateEvent(eventToAdd).pipe(
        catchError(err => this.handleError(err))
        , finalize(() => {
          this.getAllData(this.viewDate)
        }))
    } else {
      return this.addEvent(eventToAdd).pipe(
        catchError(err => this.handleError(err))
        , finalize(() => {
          this.getAllData(this.viewDate)
        }))
    }
  }

  public addEvent(event: RemindEvent): Observable<any> {
    return this.http.post(environment.apiUrl + '/reminder/' + event.calendarId + '/event/', event)


  }


  public updateEvent(event: RemindEvent): Observable<any> {
    return this.http.put(environment.apiUrl + '/reminder/' + event.calendarId + '/event/', event)
    // .subscribe(value =>  console.log("event added."),
    //   err => this.handleError(err),
    //   () => this.getAllData(this.viewDate));
  }

  saveReminder(reminder: ReminderDetails) {
    return this.http.post(environment.apiUrl + '/reminder/' + reminder.calendarId + '/details/', reminder)
  }

  createAccess(reminder: ReminderDetails) {
    return this.http.post(environment.apiUrl + '/reminder/' + reminder.calendarId + '/createAccess/', reminder)
  }

  deleteEvent(event: RemindEvent) {
    this.http.delete(environment.apiUrl + '/reminder/' + event.calendarId + '/event/' + event.id)
      .subscribe(value => this.getEvents(event.calendarId, this.viewDate));
  }

  getNames(input: string): Observable<Client[]> {
    return this.http.get<Client[]>(environment.apiUrl + '/reminder/' + this.getReminderName() + '/client/' + input);
  }

  getAllData(viewDate: Date) {
    this.observable.clearData.next()
    this.authService.loadUserData().reminders.forEach(reminder => {
      this.getEvents(reminder.calendarId, viewDate)
    })
    this.authService.loadUserData().remindersRo.forEach(reminder => {
      this.getEvents(reminder.calendarId, viewDate)
    })
    this.getForeignData()
  }

  getUserDetails(): Observable<UserDetails> {
    return this.http.get<UserDetails>(environment.apiUrl + '/user/');
  }

  getClients(calendarId: string): Observable<Client[]> {
    return this.http.get<Client[]>(environment.apiUrl + `/reminder/${calendarId}/client`);
  }

  deleteClient(client: Client) {
    return this.http.delete<any>(environment.apiUrl + `/reminder/${client.calendarId}/client/${client.id}`);
  }

  private handleError(error: HttpErrorResponse) {
    console.log("Error caught at Subscriber " + JSON.stringify(error))
    if (error.status == 400) {

      return throwError(new Error('bad request'));
    }
    if (error.status == 404)
      return throwError(new Error('not found'));
    else
      return throwError(new Error('error'));
  }

  deleteSeriesEventAfter(event: RemindEvent) {
    //FIXME .toISOString()
    this.http.delete(environment.apiUrl + '/reminder/' + event.calendarId + '/series/' + event.seriesId+ "/" +event.start)
      .subscribe(value => this.getEvents(event.calendarId, this.viewDate));
  }
}
