export class AppColor {
  public name: string
  public primary: string
  public secondary: string
}

export class ColorSet {
  early: AppColor
  late: AppColor
  earlySent: AppColor
  lateSent: AppColor
}

export class AppColors {



  static colors = {
    red: {
      name: "red",
      primary: '#ad2121',
      secondary: '#de5454'
    },
    red2: {
      name: "red",
      primary: '#ff8585',
      secondary: '#eca3a3',
    },
    blue: {
      name: "blue",
      primary: '#1e90ff',
      secondary: '#72aeff',
    },
    blue2: {
      name: "blue",
      primary: '#acd5ff',
      secondary: '#abd2ee',
    },
    yellow: {
      name: "yellow",
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    green: {
      name: "green",
      primary: '#64e97c',
      secondary: '#b2eaa6',
    },

    grey: {
      name: "grey",
      primary: '#bac3c3',
      secondary: '#a7a7a7',
    },
    pink: {
      name: "pink",
      primary: '#dc6bf1',
      secondary: '#f4bdf8',
    },
    orange: {
      name: "orange",
      primary: '#ff9600',
      secondary: '#f6ae48',
    },
    none: {
      name: "none",
      primary: '#ffffff',
      secondary: '#ffffff',
    },
    lightBlue: {
      name: "lightBlue",
      primary: '#57fcfc',
      secondary: '#45bbbb',
    },
    lightRed: {
      name: "red",
      primary: '#f3b7b7',
      secondary: '#cbb4b4',
    },
    foreign: {
      name: "foreign",
      primary: '#01034b',
      secondary: '#02056e',
    }

  };

  static colorsMap = new Map<string, AppColor>([["red",AppColors.colors.red]])

  public static getColorByName(color: string) {
    if (!color) return AppColors.colors.none
    for (const c in AppColors.colors) {
      if (AppColors.colors[c].name == color) {
        return AppColors.colors[c];
      }
    }
    return AppColors.colors.blue;
  }


  static colorSet = new Map<string, ColorSet>([
    ['blueRed', {
      early: AppColors.colors.blue,
      late: AppColors.colors.red,
      earlySent: AppColors.colors.lightBlue,
      lateSent: AppColors.colors.red2,
    }],
    ['blueRed2', {
      early: AppColors.colors.blue2,
      late: AppColors.colors.red2,
      earlySent: AppColors.colors.lightBlue,
      lateSent: AppColors.colors.lightRed,
    }]
  ])

  static getColorSetByName(colorSetName: string) {
    if(AppColors.colorSet.get(colorSetName)){
      return AppColors.colorSet.get(colorSetName)
    }
    return AppColors.colorSet.get('blueRed')
  }
}

