import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {RemindEvent} from "./app.service";

@Injectable({
  providedIn: 'root'
})
export class ObservableService {
  constructor() { }

  public remindEditEvent = new Subject<RemindEvent>();
  public remindEditObs = this.remindEditEvent.asObservable();

  public remindSmsEditEvent= new Subject<RemindEvent>();
  public remindSmsEditObs = this.remindSmsEditEvent.asObservable();

  public calendarData = new Subject<RemindEvent[]>()
  public calendarDataObs = this.calendarData.asObservable();

  public clearData = new Subject<void>()
  public clearDataObs = this.clearData.asObservable();

  public foreignEvents = new Subject<RemindEvent[]>()
  public foreignEventsObs = this.foreignEvents.asObservable();

  public loginEvent= new Subject<Function>();
  public loginObs = this.loginEvent.asObservable();
}
