import { Component, OnInit } from '@angular/core';
import {AppService, UserDetails} from "../../services/app.service";
import {AuthService, ReminderDetails} from "../../services/auth.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user: UserDetails;
  pending: boolean=true;

  constructor(private appService :AppService, private authService: AuthService) { }

  ngOnInit(): void {
    this.getUserData();
  }

  private getUserData() {
    this.appService.getUserDetails().subscribe(value => {
      console.log(value);
      this.user = value;
      this.authService.saveUserData(value)
    })
  }

  getIcalLink():string {
    return  environment.apiUrl+"/ical/"+this.user.id+"/"+this.user.publicAccessKey

  }

  getIcalViewLink():string {
    return "https://larrybolt.github.io/online-ics-feed-viewer/#feed="+environment.apiUrl+"/ical/"+this.user.id+"/"+this.user.publicAccessKey

  }

  getIcalAllEventsLink(reminder :ReminderDetails):string {
    return  environment.apiUrl+"/ical/reminder/"+reminder.calendarId+"/"+reminder.publicAccessKey

  }

  getIcalAllEventsViewLink(reminderDetail: ReminderDetails) {
    return "https://larrybolt.github.io/online-ics-feed-viewer/#feed="+this.getIcalAllEventsLink(reminderDetail)

  }

  createAccess(reminderDetail: ReminderDetails) {
    console.log("creating access")
    this.appService.createAccess(reminderDetail).subscribe(() => {
      this.getUserData()
    });
  }

  saveDetails(reminderDetail: ReminderDetails) {
    this.appService.saveReminder(reminderDetail).subscribe();
  }
}
