import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppService, Client, RemindEvent, Repeat, SmsData} from "../../services/app.service";
import {ObservableService} from "../../services/observable.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Polish} from "flatpickr/dist/l10n/pl";
import {endOfHour} from "date-fns";
import {Router} from "@angular/router";
import {AuthService, ReminderDetails} from "../../services/auth.service";
import {AppColors, ColorSet} from "../../services/app.colors";

export class NotifyOption {
  constructor(name: string, description: string, value: number, daysBefore: number = 0, atHour: number = null) {
    this.atHour = atHour;
    this.name = name;
    this.value = value;
    this.daysBefore = daysBefore;
    this.description = description;
  }

  atHour: number;
  name: string;
  description: string;
  value: number = 0;
  daysBefore: number;

}

@Component({
  selector: 'app-sms-event-dialog',
  templateUrl: './sms-event-dialog.component.html',
  styleUrls: ['./sms-event-dialog.component.css']
})
export class SmsEventDialogComponent implements OnInit {
  constructor(private observableService: ObservableService,
              private modal: NgbModal,
              private appService: AppService,
              private router: Router,
              private authService: AuthService
  ) {
  }


  private selectedReminder: string;
  private ngbModalRef: NgbModalRef;

  ngOnInit(): void {
    //FIXME should not be needed
    this.modalData = {event: this.initEvent(new Date()), action: ''};
    this.observableService.remindSmsEditObs.subscribe(value => {
      console.log(value)
      let copy = {...value};
      this.showEdit(copy);
      this.beforeSubmit = true;
    })
    this.observableService.calendarDataObs.subscribe(data => {
      this.getReminderDataFromStorage();
    });
  }

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  locale = Polish;

  invalidTimeMessage = 'Czas powiadomienia musi być przed startem';
  emptyErrorMessage = 'Wartość wymagana';
  beforeSubmit = true;
  keyword = 'name';
  static dayStartHour = 8;
  dayEndHour = 22;
  modalData: {
    action: string;
    event: RemindEvent;
  };
  reminders: ReminderDetails[];

  autoCompleteData: Client[] = [];

  private static dayBeforeAt16 = new NotifyOption("day_before_at_16", 'dzien wczesniej o 16', 0, 1, 16);
  static notifyOptions: NotifyOption[] = [
    new NotifyOption("day_before_at_12", 'dzien wczesniej o 12', 0, 1, 12),
    SmsEventDialogComponent.dayBeforeAt16,
    new NotifyOption("day_before_at_20", 'dzien wczesniej o 20', 0, 1, 20),
    new NotifyOption("at_dayStartHour", 'rano o ' + SmsEventDialogComponent.dayStartHour, 0, 0, SmsEventDialogComponent.dayStartHour),
    new NotifyOption("0_min_before", '0 min przed', 0),
    new NotifyOption("5_min_before", '5 min przed', 5),
    new NotifyOption("15_min_before", '15 min przed', 15),
    new NotifyOption("30_min_before", '30 min przed', 30),
    new NotifyOption("60_min_before", '60 min przed', 60),
    new NotifyOption("120_min_before", '120 min przed', 120)];

  defaultNotify: NotifyOption = SmsEventDialogComponent.dayBeforeAt16;
  selectedNotify: NotifyOption = this.defaultNotify;

  repeat: boolean = false;

  public initEvent(startDate: Date): RemindEvent {
    return {start: startDate, end: endOfHour(startDate)}
  }

  private showEdit<T>(value: RemindEvent) {
    value.start = new Date(value.start)
    value.end = new Date(value.end)
    value.smsData.notifyAt = new Date(value.smsData.notifyAt)
    this.selectedNotify = SmsEventDialogComponent.notifyOf(value.smsData.notifyOption)
    this.setModelData(value);
    this.ngbModalRef = this.modal.open(this.modalContent, {size: 'lg'});
  }

  private setModelData(remindEvent: RemindEvent) {
    console.log(remindEvent)
    // remindEvent.smsData.notifyAt = this.computeNotify(this.dayBeforeAt16,remindEvent.start)
    this.modalData.event = remindEvent
    this.repeat = this.modalData?.event.repeat != null;
  }

  public static computeNotify(notifyOption: NotifyOption, date) {
    let result: Date = new Date(date);
    if (notifyOption.daysBefore != 0) {
      result = SmsEventDialogComponent.minusDays(notifyOption.daysBefore, result);
    }
    if (notifyOption.atHour) {
      result = SmsEventDialogComponent.setHourForDate(result, notifyOption.atHour);
    } else {
      result = SmsEventDialogComponent.minusMinutes(notifyOption.value, result);
    }
    console.log(result)
    return result;
  }

  setCurrentNotify(event: any) {
    if (event.selectedDates && this.selectedNotify) {
      this.getSmsData().notifyAt = SmsEventDialogComponent.computeNotify(this.selectedNotify, event.selectedDates[0]);
    } else if (this.modalData?.event.start && this.selectedNotify) {
      this.getSmsData().notifyAt = SmsEventDialogComponent.computeNotify(this.selectedNotify, this.modalData?.event.start);
    } else if (event.selectedDates) {
      this.getSmsData().notifyAt = SmsEventDialogComponent.computeNotify(this.defaultNotify, event.selectedDates[0]);
    } else {
      console.log('else');
    }
    this.getSmsData().notifyOption = this.selectedNotify.name;
  }

  public getSmsData(): SmsData {
    return this.modalData?.event?.smsData;
  }

  saveEvent(eventToAdd: RemindEvent) {
    eventToAdd.end = endOfHour(eventToAdd.start)
    this.beforeSubmit = false;
    if (this.validate()) {
      if (!this.authService.isAuthenticated()) {
        this.observableService.loginEvent.next(() => {
          this.ngbModalRef.close()
          this.appService.saveOrUpdate(eventToAdd).subscribe(value => {
          });
          this.router.navigate(['/calendar']);
        })
        // this.appService.getData(eventToAdd.calendarId)
      } else {
        this.appService.saveOrUpdate(eventToAdd).subscribe(value => {
        });
        this.router.navigate(['/calendar']);
        return true;
      }
      this.beforeSubmit = true;
    }
    return false;
  }

  public deleteEvent(event: RemindEvent) {
    this.appService.deleteEvent(event);
  }


  private static setHourForDate(fromDate, hour: number) {
    let date = new Date(fromDate);
    date.setHours(hour);
    date.setSeconds(0);
    date.setMinutes(0);
    return date;
  }

  private static minusDays(d, date: Date) {
    let date1 = new Date(date);
    date1.setTime(date.getTime() - (d * 24 * 60 * 60 * 1000));
    return date1;
  }

  private static minusMinutes(m, date: Date): Date {
    let date1 = new Date(date);
    date1.setTime(date.getTime() - (m * 60 * 1000));
    return date1;
  }

  validate() {
    return this.datesValid() && this.messageValid() && this.phoneNumberValid();
  }

  phoneNumberValid() {
    return this.beforeSubmit || this.getSmsData().phoneNumber;
  }

  messageValid() {
    return this.beforeSubmit || this.getSmsData().message && this.getSmsData().message.length <= this.countLimit();
  }

  datesValid() {
    return this.beforeSubmit || new Date(this.getSmsData().notifyAt).getTime() <= new Date(this.modalData?.event.start).getTime();
  }

  public getAllNames() {
    this.appService.getNames('.*').subscribe(value => {
      this.autoCompleteData = value;
    });
  }

  onFocused(e) {
    this.getAllNames();
  }

  getNamesData(): Client[] {
    return this.autoCompleteData;
  }


  onChangeSearch(input: string) {
    console.log("changed" + input)
    this.getSmsData().name = input;
    this.appService.getNames(input).subscribe(value => {
      this.autoCompleteData = value;
    });
  }

  clearSelected() {
    console.log("clear");
    this.getSmsData().name = '';
    this.getSmsData().phoneNumber = '';
    this.getSmsData().clientId = '';
    // this.modalData.event.smsData.message = '';
    this.getSmsData().info = '';
  }

  selectEvent(item: Client) {
    console.log(item);
    this.getSmsData().name = item.name;
    this.getSmsData().phoneNumber = item.phoneNumber;
    this.getSmsData().clientId = item.id;
    if (item.message && item.message != '') {
      this.getSmsData().message = item.message;
    }
    this.getSmsData().info = item.info;
  }

  editable() {
    return this.getSmsData().foreign == null && this.modalData?.event.id != null
  }

  private getReminderDataFromStorage() {
    //TODO assign calendars to colors
    this.reminders = this.authService.loadUserData().reminders
  }

  public getColorSet(colorSetName: string): ColorSet {
    return AppColors.getColorSetByName(colorSetName)
  }

  public countLimit(): number {
    let limit = this.getSmsData().replaceNonAscii ? 160 : 80;
    limit = this.getSmsData().addEventTime ? limit - 30 : limit
    return this.getSmsData().allowDouble ? limit * 2 : limit;
  }

  public resetMessageInput() {
    let limited = this.getSmsData().message.substr(0, this.countLimit());
    this.getSmsData().message = null;
    this.getSmsData().message = limited;
  }

  public initRepeat() {
    if (!this.repeat) {
      this.modalData.event.repeat = new Repeat();
      this.modalData.event.seriesId = null;
    } else {
      this.modalData.event.repeat = null;
    }
  }

  public static notifyOf(notifyOption: string): NotifyOption {
    let found: NotifyOption | undefined = SmsEventDialogComponent.notifyOptions.find(value => value.name == notifyOption);
    if (found) {
      return found;
    } else {
      return new NotifyOption("", "", null, null)
    }
  }

  public changeWithFuture(event: RemindEvent) {
    this.appService.deleteSeriesEventAfter(event);
  }

  repeatNotChanged(): boolean {
    console.log(this.modalData.event.seriesId)
    return this.modalData.event.seriesId != null;
  }

  getNotifyOptions() {
    return SmsEventDialogComponent.notifyOptions;
  }
}


