import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from 'rxjs';
import {UserDetails} from "./app.service";
import {catchError, map} from "rxjs/operators";

export class AuthData {
  phoneNumber: string;
  token?: string;
}

export class LoginResponse {
  pending: boolean;
  authenticated: boolean;
  token: string;
  user: UserDetails
}

export class ReminderDetails {

  calendarId?: string
  name?: String
  colorSet?: string
  publicAccessKey?: string
  importUrl?: string
  defaultMessage?: string
}

export class CodeVerifyDto {
  code: number;
  phoneNumber: string;
}

function getTokenFromStorage() {
  return localStorage.getItem("token");
}

function removeTokenFromStorage() {
  return localStorage.removeItem("token");
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) {
  }

  getAuthToken(): string {
    return getTokenFromStorage();
  }

  getUserDetails(): Observable<UserDetails> {
    return this.http.get<UserDetails>(environment.apiUrl + '/user/');
  }

  public auth(data: UserDetails): Observable<LoginResponse> {
    console.log('auth ');
    return this.http.post<LoginResponse>(environment.apiUrl + '/auth/', data);
  }

  public approveToken(data: CodeVerifyDto): Observable<LoginResponse> {
    console.log('approveToken ');
    return this.http.post<LoginResponse>(environment.apiUrl + '/approve-token/', data);
  }

  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    this.router.navigate(['/home'])
  }

  setAuthResult(value: LoginResponse) {
    console.log('logged as $value' + JSON.stringify(value))
    this.saveUserData(value.user);
    localStorage.setItem("token", value.token)
  }

  saveUserData(user: UserDetails) {
    localStorage.setItem("userData", JSON.stringify(user))
  }

  public loadUserData(): UserDetails {
    let dataFromStorage = JSON.parse(localStorage.getItem("userData")) as UserDetails;
    //FIXME backward compatibility
    if (dataFromStorage?.ownedReminders != null) {
      dataFromStorage.reminders = dataFromStorage.reminders.concat(dataFromStorage.ownedReminders)
    }
    return dataFromStorage ? dataFromStorage : {reminders: []} as UserDetails
  }

  public getName() {
    return this.loadUserData()?.name;
  }

  public getPrimaryCalendar() {
    return this.loadUserData()?.primaryReminder;
  }

  isAuthenticated() {
    return this.getAuthToken() != null
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = getTokenFromStorage();

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization",
          "Bearer " + idToken)
      });

      return next.handle(cloned).pipe(catchError(err => {
        if ([401, 403].includes(err.status)) {
          console.log('401 or 403');
          // auto logout if 401 or 403 response returned from api
          this.authService.logout();
        }

        const error = err.error?.message || err.statusText;
        console.error(error);

        throw err
      }));
    } else {
      return next.handle(req);
    }
  }

}
