<section id="hero">

  <div class="container">
    <div class="row">
      <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
        <div>
          <h1>Chcesz przypomnieć komuś o czymś ?</h1>
          <h2>Wyślij mu wiadomość przed wydarzeniem</h2>
          <a class="btn-get-started" (click)="addSmsEventWithDialog()"  >Przypomnij Się</a>
        </div>
      </div>
      <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left">
        <img src="assets/img/hero-img.png" class="img-fluid" alt="">
      </div>
    </div>
  </div>

</section><!-- End Hero -->



