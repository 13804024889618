<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Wylogowanie</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer" >
    <button type="button" class="btn btn-outline-secondary" (click)="logout();close();">
      Wyloguj
    </button>
  </div>
</ng-template>
