
<!-- ======= Contact Section ======= -->
<section class="contact section-bg">
  <div class="container">

    <div class="section-title" data-aos="fade-up" >
      <h2>{{user.name}}</h2>
    </div>

    <div class="row">

      <div class="d-flex " data-aos="fade-right">
        <div class="info">
          <div class="address">
            <i class="bi"></i>
            <h4>Reminder:</h4>
            <p>{{user.primaryReminder}}</p>
          </div>

          <div class="email">
            <i class="bi bi-envelope"></i>
            <h4>Email:</h4>
            <p>{{user.email}}</p>
          </div>

          <div class="phone">
            <i class="bi bi-phone"></i>
            <h4>Numer:</h4>
            <p>{{user.phoneNumber}} </p>
          </div>

          <div class="phone">
            <i class="bi bi-alarm"></i>
            <h4>Liczba smsow:</h4>
            <p>{{user.smsAmount}} </p>
          </div>
          <div class="ical">
            <i class="bi bi-alarm"></i>
            <h4>Eksport powiązanych powiadomień:</h4>
            <p [hidden]="!user.publicAccessKey">{{getIcalLink()}}<br>
            <a href="{{getIcalViewLink()}}" target="new2"> pokaż</a></p>
          </div>
        <div>


        </div>
          <i class="bi bi-activity"></i>
          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch " data-aos="fade-left">
            <form  method="post" role="form" class="php-email-form" >
              {{user.reminders[0].name}}
              <br>
              <div class="form-group mt-3" [hidden]="!user.reminders[0]">
                <label >eksport url</label>
                <a [routerLink]="" (click)="createAccess(user.reminders[0])" [hidden]="user.reminders[0].publicAccessKey">
                utwórz
                </a>
                <div [hidden]="!user.reminders[0].publicAccessKey">{{getIcalAllEventsLink(user.reminders[0])}}
                <a href="{{getIcalAllEventsViewLink(user.reminders[0])}}" target="new"> pokaż</a>
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="import">import url</label>
                <input type="text" class="form-control" id="import" name="import" required
                       [(ngModel)]="user.reminders[0].importUrl">
              </div>
              <div class="form-group mt-3">
                <label for="msg">wiadomość domyślna</label>
                <input type="text" class="form-control" id="msg" name="msg"  required
                       [(ngModel)]="user.reminders[0].defaultMessage">
              </div>
              <div class="text-lg-end"><button type="submit" (click)="saveDetails(user.reminders[0])">Zapisz</button></div>
            </form>
          </div>



        </div>

      </div>

    </div>

  </div>
</section><!-- End Contact Section -->
