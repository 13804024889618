import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {CalendarComponent} from './calendar/calendar.component';
import {LoginDialogComponent} from "./login-dialog/login-dialog.component";
import {LogoutDialogComponent} from "./logout-dialog/logout-dialog.component";
import {ContactComponent} from "./contact/contact.component";
import {UserComponent} from "./user/user.component";
import {ClientsComponent} from "./clients/clients.component";


const routes: Routes = [
  {path: 'calendar', component: CalendarComponent},
  {path: 'home', component: HomeComponent},
  {path: 'login', component: LoginDialogComponent},
  {path: 'logout', component: LogoutDialogComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'user', component: UserComponent},
  {path: 'clients', component: ClientsComponent},
  {path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
